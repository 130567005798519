$primary-color: #cc9c59;
$white-color: #eee;
$lite-gray: #f9f9f9;

.app-header {
  display: none;
}

.app-wrapper {
  margin-top: 0 !important;
}

// card container
.student-container {
  min-height: 500px;
}

.page-title-zad h1 {
  color: $primary-color !important;
  font-size: 26px !important;
  margin-bottom: 20px !important;
}

.card-title-zad {
  margin-bottom: 12px !important;
  margin-top: 35px;
}

//Start ToggleButton
.active-zad {
  background-color: $primary-color;
  color: $white-color !important;
  &:hover {
    background-color: $primary-color;
  }
}
// End ToggleButton

// Start Pagination
.zad-pag {
  &.active {
    .page-link {
      background-color: $primary-color !important;
      color: $white-color !important;

      &:hover {
        background-color: $primary-color !important;
        color: $white-color !important;
      }
    }
  }

  &:hover {
    .page-link {
      color: $primary-color !important;
    }
  }
}
// End Pagination

// Start group buttons
.zad-toggle-btn {
  .btn.btn-primary:hover:not(.btn-active) {
    background-color: $lite-gray !important;
    color: #{$primary-color} !important;
    font-weight: 600;
  }

  .btn-check:checked + .btn.btn-primary,
  .btn.btn-primary.active {
    background-color: #f9f9f9 !important;
    font-weight: bolder;
    // color: #{$primary-color} !important;
    // border-bottom: 2px solid #{$primary-color} !important;
    border-radius: 0;
  }

  .btn {
    background-color: transparent;
    color: #333;

    &:hover {
      background-color: #{$primary-color} !important;
      color: $white-color !important;
    }
  }
}
// End group buttons

.passCard .card {
  box-shadow: none;
  border: 0;
}

//

.btn-zad {
  background-color: $primary-color;
  color: $white-color;
}

.innerpage-header {
  background-color: #fff;
  box-shadow: 0 0 5px #e9e7e7;
  padding: 10px;
  border-radius: 5px;
}

.study-period-date {
  .progress-container {
    .progress {
      margin: auto 0;
    }
    .box-date {
      color: #3f4254;
      font-size: 14px;
      font-weight: 500;
      i {
        font-size: 14px;
        padding: 0 5px;
      }
    }
  }
}

//

.filter-label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

$categories: (
  'AQ': '#67A7CD',
  'SR': '#C0822A',
  'FQ': '#9B3B4B',
  'TS': '#67A89D',
  'HD': '#84AE45',
  'TB': '#E8766A',
  'AR': '#06417E',
);

@each $name, $color in $categories {
  .cat-color-#{$name} {
    color: #{$color} !important;
    font-weight: 600;
  }
}

@media (max-width: 991px) {
  .btn-zad {
    padding: 5px 20px !important;
  }
  .card-zad-sm {
    min-height: 95%;
    margin-bottom: 10px;
  }
  .study-period-date {
    .title {
      margin-bottom: 10px;
    }
    .progress-container {
      .progress {
        margin: auto 0;
      }
      .box-date {
        color: #3f4254;
        font-size: 10px;
        font-weight: 500;
        i {
          font-size: 12px;
          padding: 0 5px;
        }
      }
    }
  }

  .zad-toggle-btn {
    label {
      padding: 7px !important;
      font-size: 9px !important;
    }
  }
}
